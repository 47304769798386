// import PropTypes from "prop-types";
import React from "react";
import { withTrans } from '../i18n/withTrans';


function Wrapper({ children }) {
  return (
    <>
    {children}
    </>
  );
}

export default withTrans(Wrapper);
