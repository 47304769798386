import i18next from 'i18next';

i18next.init({
    fallbackLng: false,
    lng: "en",
    resources: {
        en: {
            common: require('../locales/en/common.json')
        },
        jp: {
            common: require('../locales/jp/common.json')
        },

    },
    ns: ['common', 'index', 'ai', 'medtech'],
    defaultNS: 'common',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    // react: {
    //     wait: true,
    // },

    saveMissing: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
        throw new Error(`Missing Key: ${ns}:"${key}" in language "${lngs}".`);
      },
});

i18next.languages = ['en', 'jp'];

i18next.addResources('en', 'index', require('../locales/en/index.json'));
i18next.addResources('en', 'ai', require('../locales/en/ai.json'));
i18next.addResources('en', 'medtech', require('../locales/en/medtech.json'));

i18next.addResources('jp', 'index', require('../locales/jp/index.json'));
i18next.addResources('jp', 'ai', require('../locales/jp/ai.json'));
i18next.addResources('jp', 'medtech', require('../locales/jp/medtech.json'));

export default i18next;